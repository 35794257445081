import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { EllipsisText } from '../../EllipsisText';
import { makeStyles } from 'tss-react/mui';
import SelectMenu from '../SelectList/SelectMenu';
import { NodonMenuProps, SelectListChildren } from '../menu.model';
import { useIsReadonly } from '../../../hooks/user.hook';

interface TextMenu<T> extends NodonMenuProps {
  items: T[];
  label: string;
  children: SelectListChildren<T>;
}

const TextMenu = <T,>({
  items,
  anchor,
  label,
  tooltip,
  buttonProps,
  menuWidth,
  children,
  onOpen,
  onClose,
  onSearch,
}: TextMenu<T>) => {
  const { classes } = useStyles();
  const readonly = useIsReadonly();

  const closeMenu = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onOpen?.(e.currentTarget);
    },
    [onOpen],
  );

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltip}>
        <Button
          {...buttonProps}
          variant="text"
          size="small"
          color="inherit"
          disabled={readonly || items.length === 0}
          classes={{
            root: classes.button,
            endIcon: classes.endIcon,
          }}
          endIcon={<ArrowDropDown />}
          onClick={openMenu}
        >
          <EllipsisText>{label}</EllipsisText>
        </Button>
      </Tooltip>

      <SelectMenu
        anchor={anchor}
        items={items}
        width={menuWidth}
        enableSearch={items.length > 25}
        enableVirtualization={items.length > 25}
        onClose={closeMenu}
        onSearch={onSearch}
        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        {(item, style, index) => children(item, style, index)}
      </SelectMenu>
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  button: {
    fontWeight: 400,
    fontSize: 12,
    paddingRight: spacing(2.5),
    maxWidth: 175,
    textTransform: 'none',
    border: `1px solid transparent`,

    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${palette.text.secondary}`,
    },
  },
  endIcon: {
    marginLeft: spacing(1),
    color: palette.text.secondary,
  },
  itemButton: {
    fontSize: 12,
  },
}));

export default TextMenu;
