import {
  Divider,
  ListItemButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { CSSProperties } from 'react';
import { EllipsisText } from '../../EllipsisText';
import { MENU_ITEM_HEIGHT } from '../../../../../shared/constants';
import { otherNodonProduct } from '../../../../../shared/templates/categories/other';
import { getSbefDividerItems } from '../../../../../shared/templates/categories/categories-properties.helpers';
import { ConcreteType } from '../../../../../shared/templates/categories/concrete/concrete.model';
import { BoardType } from '../../../../../shared/templates/categories/gypsum/gypsum.model';
import { InsulationMaterial } from '../../../../../shared/templates/categories/insulation/insulation.model';
import { WoodType } from '../../../../../shared/templates/categories/wood/wood.model';

interface SBEFMenuItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  // item: IElementPropertyOption;
  code: string;
  selected?: boolean;
  showTooltip?: boolean;
  indentAfterFirstWord?: boolean;
  disableDivider?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const SBEFMenuItem: React.FC<SBEFMenuItemProps> = ({
  code,
  style,
  selected,
  showTooltip,
  indentAfterFirstWord,
  disableDivider,
  onClick,
}) => {
  const [first, ...rest] = indentAfterFirstWord ? code.split(' ') : [];

  return (
    <>
      {!disableDivider && <DividerItem code={code} style={style} />}
      <ListItemButton
        style={{ ...style, zIndex: 1 }}
        data-item={code}
        onClick={onClick}
        selected={selected}
      >
        <Typography {...listItemTextStyle}>
          <EllipsisText showTooltip={showTooltip}>{first || code}</EllipsisText>
        </Typography>

        {rest.length > 0 && (
          <Typography {...listItemTextStyle}>
            <EllipsisText showTooltip={showTooltip}>
              {rest.join(' ')}
            </EllipsisText>
          </Typography>
        )}
      </ListItemButton>
    </>
  );
};

interface DividerItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  // item: IElementPropertyOption;
  code: string;
}

const DividerItem = ({ style, code }: DividerItemProps) => {
  const isNodonProduct = otherNodonProduct && code === otherNodonProduct.name;

  const isDividerItem = dividerItems.includes(code);

  if (!isNodonProduct && !isDividerItem) {
    return null;
  }
  if (!style) {
    return <Divider />;
  }
  return (
    <Divider
      style={{
        ...style,
        zIndex: 0,
        top: isNodonProduct ? style.top : Number(style.top) - MENU_ITEM_HEIGHT,
      }}
    />
  );
};

const dividerItems: string[] = [
  ConcreteType.Other,
  ConcreteType.BalconyAndStairs,
  ConcreteType.Columns,
  BoardType.CelluloseFibre,
  InsulationMaterial.EPS,
  WoodType.OSB,
  ...getSbefDividerItems(),
];

const listItemTextStyle: TypographyProps = {
  minWidth: 30,
  variant: 'body2',
  display: 'flex',
  alignItems: 'center',
} as const;

export default SBEFMenuItem;
