import { sbefCodes, sbefCodesLookup } from '../../construction/sbef-codes';
import {
  SbefCode,
  SbefLanguage,
} from '../../construction/construction.interface';

const formatSbefCode = (
  { id, description }: SbefCode,
  lang: SbefLanguage = 'en',
) => `${id}. ${description[lang]}`;

export const getFormatedSBEFCodes = () => {
  return sbefCodes.map(({ id, description }) =>
    formatSbefCode({ id, description }),
  );
};

export const getSbefDividerItems = () => {
  const items: string[] = [];

  for (let i = 0; i <= 10; i++) {
    if (i === 10) {
      i = 101;
    }

    const item = sbefCodesLookup[i];

    if (item) {
      items.push(formatSbefCode(item));
    }
  }
  return items;
};
