import {
  ElementPropertyInputType,
  ElementPropertyName,
  IFactoryExpressionProperty,
  IFactoryProperty,
  IFactorySelectProperty,
} from '../../models/element_property.interface';
import { getFormatedSBEFCodes } from './categories-properties.helpers';

export const sbefCodeProperty: IFactorySelectProperty = {
  type: ElementPropertyInputType.Select,
  name: ElementPropertyName.SbefCode,
  options: ElementPropertyName.SbefCode,
  inheritFallback: true,
};

export const lifetimeProperty: IFactoryExpressionProperty = {
  type: ElementPropertyInputType.Expression,
  name: ElementPropertyName.Lifetime,
  unit: 'year',
  inheritFallback: true,
  fallbackCount: 'building_lifetime',
  min: 0,
};

export const mutualProperties: IFactoryProperty[] = [
  sbefCodeProperty,
  lifetimeProperty,
];

export const SBEF_CODES = Object.freeze(['none', ...getFormatedSBEFCodes()]);
