import React, { MouseEvent, useCallback, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IElement } from '../../../../../../shared/models/project.interface';
import { ArrowDropDown } from '@mui/icons-material';
import { useRecipe } from '../../../../hooks/useRecipes';
import { RecipeLabel } from './RecipeLabel';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { elementListItemSelectIconStyles } from '../../../../style/constants';
import RecipeSelector from './RecipeSelector';

interface RecipeMenuProps {
  element: IElement;
  isSelectViewOnly?: boolean;
  hideLabel?: boolean;
}

const RecipeMenu: React.FC<RecipeMenuProps> = ({
  element,
  isSelectViewOnly,
  hideLabel,
}) => {
  const { classes } = useStyles();

  const readonly = useIsReadonly();
  const selectedRecipe = useRecipe(element);

  const [anchor, setAnchor] = useState<HTMLElement>();

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );

  const handleMenuClose = useCallback(() => setAnchor(undefined), []);

  // TODO: cleanup this component when use cases are clear
  return (
    <>
      {!!hideLabel && (
        <IconButton
          size="small"
          color={isSelectViewOnly ? 'inherit' : 'secondary'}
          disabled={readonly}
          edge="start"
          className={classes.labelButton}
          sx={elementListItemSelectIconStyles}
          onClick={handleButtonClick}
        >
          <ArrowDropDown />
        </IconButton>
      )}
      {!hideLabel && (
        <Button
          disabled={readonly}
          size="medium"
          color={isSelectViewOnly ? 'inherit' : 'secondary'}
          variant={isSelectViewOnly ? 'text' : 'contained'}
          fullWidth={!isSelectViewOnly}
          endIcon={isSelectViewOnly ? <ArrowDropDown /> : null}
          className={classes.labelButton}
          onClick={handleButtonClick}
        >
          <RecipeLabel element={element} recipe={selectedRecipe}></RecipeLabel>
        </Button>
      )}
      {!!anchor && (
        <RecipeSelector onClose={handleMenuClose} element={element} />
      )}
    </>
  );
};

const useStyles = makeStyles()(() => ({
  labelButton: {
    textTransform: 'capitalize',
  },
  menu: {
    width: '20%',
    minWidth: 275,
    maxWidth: 475,
  },
}));

export default RecipeMenu;
