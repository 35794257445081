import React, {
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
} from 'react';
import { Box, Modal, SxProps, Tab, Tabs } from '@mui/material';
import { NodonTheme } from '../../../../style';
import { IElement } from '../../../../../../shared/models/project.interface';
import SelectList from '../../../menus/SelectList/SelectList';
import RecipeListItem from '../../../ElementList/ListItems/RecipeListItem';
import { itemBelongsToTemplateOrganization } from '../../../../../../shared/helpers/organization_helpers';
import { useRecipes } from '../../../../store/recipe/recipe.hook';
import SBEFMenuItem from '../../../menus/ListItems/SBEFMenuItem';
import { getListItemKey } from '../../../../../../shared/helpers/utils.helpers';
import { getFormatedSBEFCodes } from '../../../../../../shared/templates/categories/categories-properties.helpers';
import { ElementPropertyName } from '../../../../../../shared/models/element_property.interface';
import { isElementSbefProperty } from '../../../../../../shared/helpers/element_property_helpers';
import { sortBy } from 'lodash';

interface RecipeSelectorProps {
  onClose: () => void;
  element: IElement;
}

const RecipeSelector: React.FC<RecipeSelectorProps> = ({
  onClose,
  element,
}) => {
  const recipes = useRecipes();
  const sbefCodes = useMemo(() => getFormatedSBEFCodes(), []);

  const [selectedTab, setSelectedTab] = useState<'organization' | 'template'>(
    'organization',
  );

  const [selectedSBEF, setSelectedSBEF] = useState(
    element.properties?.find(isElementSbefProperty)?.count ?? '',
  );

  const filteredRecipes = useMemo(() => {
    const templateRecipes = recipes.filter(itemBelongsToTemplateOrganization);
    const organizationRecipes = recipes.filter(
      (recipe) => !itemBelongsToTemplateOrganization(recipe),
    );
    const filtered =
      selectedTab === 'organization' ? organizationRecipes : templateRecipes;

    return selectedSBEF
      ? filtered.filter(
          (recipe) =>
            recipe.category_property_value_record[
              ElementPropertyName.SbefCode
            ] === selectedSBEF,
        )
      : filtered;
  }, [recipes, selectedTab, selectedSBEF]);

  const sortedRecipes = useMemo(() => {
    return sortBy(filteredRecipes, 'name');
  }, [filteredRecipes]);

  const selectTab = useCallback(
    (event: SyntheticEvent, tab: 'organization' | 'template') => {
      setSelectedTab(tab);
    },
    [],
  );

  const handleSBEFClick = useCallback(
    ({
      currentTarget: {
        dataset: { item },
      },
    }: MouseEvent<HTMLDivElement>) => {
      if (item) {
        setSelectedSBEF(item !== selectedSBEF ? item : '');
      }
    },
    [selectedSBEF],
  );

  return (
    <Modal
      open
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={containerStyles}>
        <Box display="flex" alignItems="center" height={HEADER_HEIGHT}>
          <Tabs value={selectedTab} onChange={selectTab}>
            <Tab label="Organisation" value="organization" />
            <Tab label="Library" value="template" />
          </Tabs>
        </Box>

        <Box display="flex" height={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <SelectList items={sbefCodes} width="medium">
            {(code, style, index) => (
              <SBEFMenuItem
                key={getListItemKey(index, code)}
                code={code}
                onClick={handleSBEFClick}
                selected={code === selectedSBEF}
              />
            )}
          </SelectList>

          <Box display="flex" flexDirection="column" flex={1}>
            <SelectList items={sortedRecipes} enableSearch enableVirtualization>
              {(item, style) => (
                <RecipeListItem
                  key={item.id}
                  style={style}
                  recipe={item}
                  element={element}
                  onSelect={onClose}
                />
              )}
            </SelectList>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const HEADER_HEIGHT = 50;

const containerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 100px)',
  height: 'calc(100% - 100px)',
  background: NodonTheme.palette.background.default,
  borderRadius: 1,
} as const;

export default RecipeSelector;
