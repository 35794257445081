import {
  Box,
  ListItemButton,
  Tooltip,
  ListItemButtonProps,
  PopoverOrigin,
} from '@mui/material';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import SelectMenu from './SelectMenu';
import { NodonMenuItemProps, SelectListChildren } from '../menu.model';
import { useMouseEventCallback } from '../../../hooks/events.hook';

interface ItemProps<T> extends Omit<NodonMenuItemProps, 'items'> {
  items?: T[];
}

interface ListItemProps<T extends ItemProps<T>>
  extends Omit<ListItemButtonProps, 'children'> {
  children: SelectListChildren<T>;
  item: T;
  tooltip?: string;
  style?: CSSProperties;
}

const SelectMenuItem = <T extends ItemProps<T>>({
  children,
  style,
  item,
  tooltip,
  ...buttonProps
}: ListItemProps<T>) => {
  const [anchor, setAnchor] = useState<Element>();

  const handleItemClick = useMouseEventCallback((e) => {
    if (item.items) {
      setAnchor(e.currentTarget);
      return;
    }
    buttonProps.onClick?.(e as React.MouseEvent<HTMLDivElement>);
  });

  const handleCloseSubMenu = useCallback(() => {
    setAnchor(undefined);
  }, []);

  const origin = useMemo<PopoverOrigin>(() => {
    const horizontalOffset = anchor?.clientWidth ?? 100;
    return { vertical: 'top', horizontal: -horizontalOffset };
  }, [anchor]);

  return (
    <>
      <Tooltip style={style} title={tooltip} disableInteractive>
        <Box>
          <ListItemButton {...buttonProps} onClick={handleItemClick}>
            {children(item)}
          </ListItemButton>
        </Box>
      </Tooltip>

      {item.items?.length && (
        <SelectMenu
          items={item.items}
          anchor={anchor}
          transformOrigin={origin}
          onClose={handleCloseSubMenu}
        >
          {(subItem) => {
            const { id, tooltip, divider, disabled, onClick } = subItem;
            const props: Partial<ListItemProps<T>> = {
              tooltip,
              divider,
              disabled,
              onClick,
            };
            return (
              <SelectMenuItem key={id} item={subItem} {...props}>
                {children}
              </SelectMenuItem>
            );
          }}
        </SelectMenu>
      )}
    </>
  );
};

export default SelectMenuItem;
