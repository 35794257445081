import React, { useCallback } from 'react';
import { IRowInput, Row } from '../Row';
import { RowCell } from '../RowCell';
import { Recipe } from '../../../../../shared/models/recipe.interface';
import { ELEMENT_LIST_ITEM_HEIGHT, LIST_SPACING } from '../list.constants';
import { ListItemButton } from '@mui/material';
import { useApplyRecipe } from '../../../hooks/useRecipes';
import { IElement } from '../../../../../shared/models/project.interface';

interface RecipeListItemProps extends IRowInput {
  recipe: Recipe;
  element: IElement;
  onSelect: () => void;
}

const RecipeListItem: React.FC<RecipeListItemProps> = ({
  recipe,
  element,
  onSelect,
  ...rowProps
}) => {
  const applyRecipe = useApplyRecipe();

  const selectRecipe = useCallback(() => {
    applyRecipe(element, recipe.id);
    onSelect();
  }, [applyRecipe, element, recipe.id, onSelect]);

  return (
    <Row
      {...rowProps}
      padding
      height={ELEMENT_LIST_ITEM_HEIGHT}
      spacing={LIST_SPACING.DEFAULT}
      selected={recipe.id === element.recipe_id}
    >
      <ListItemButton onClick={selectRecipe}>
        <RowCell>{recipe.name}</RowCell>
      </ListItemButton>
    </Row>
  );
};

export default RecipeListItem;
