import { useMemo, useState } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import { isDefined } from '../../../shared/helpers/array_helpers';

interface UseSearchProps<T> {
  items: T[];
  options?: IFuseOptions<T>;
}

export const useSearch = <T>({ items, options }: UseSearchProps<T>) => {
  const [searchString, setSearchString] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(items, {
        includeScore: true,
        shouldSort: false,
        minMatchCharLength: 1,
        threshold: 0.2,
        distance: 1000, // Distance * threshold is the amount of characters searched
        keys: ['id', 'name', 'value', 'label'],
        ...options,
      }),
    [items, options],
  );

  const filteredItems = useMemo(() => {
    const filtered = fuse
      .search(searchString)
      .map((foundItem) => items[foundItem.refIndex])
      .filter(isDefined);

    return searchString ? filtered : items;
  }, [fuse, items, searchString]);

  return useMemo(
    () => ({ searchString, setSearchString, filteredItems }),
    [searchString, filteredItems],
  );
};
