import React, { useCallback, useMemo, useState } from 'react';
import SearchField from '../SearchField';
import { SxProps, TextFieldProps } from '@mui/material';
import {
  getElementSearchString,
  setElementSearchString,
} from '../../hooks/filter-elements.hook';
import {
  getProjectSearchString,
  setProjectSearchString,
} from '../../hooks/filter-projects.hook';

interface SearchElementsProps {
  variant: 'projects' | 'elements';
}

const SearchElements: React.FC<SearchElementsProps> = ({ variant }) => {
  const initialSearchString =
    variant === 'elements'
      ? getElementSearchString()
      : getProjectSearchString();

  const [searchString, setSearchString] = useState(initialSearchString ?? '');

  const handleSearch = useCallback(
    (str: string) => {
      setSearchString(str);

      variant === 'elements'
        ? setElementSearchString(str)
        : setProjectSearchString(str);
    },
    [setSearchString, variant],
  );

  const styles = useMemo<SxProps>(() => {
    return {
      width: variant === 'elements' ? 'auto' : '40%',
      justifyContent: 'center',
    };
  }, [variant]);

  return (
    <SearchField
      placeholder={`Search ${variant}`}
      debounce={variant === 'elements' ? 300 : 0}
      autoFocus={variant === 'projects'}
      sx={styles}
      slotProps={slotProps}
      value={searchString}
      onChange={handleSearch}
    />
  );
};

const slotProps: TextFieldProps['slotProps'] = {
  input: { sx: { padding: '0 4px 0 0' } },
  htmlInput: { sx: { padding: '5px 0px' } },
} as const;

export default SearchElements;
