import React, {
  FC,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FocusEvent,
  useRef,
  KeyboardEvent,
} from 'react';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { useDebounce } from '../hooks/hooks';
import { useKeyboardEventCallback } from '../hooks/events.hook';

interface SearchFieldProps {
  value: string;
  debounce?: number;
  autoFocus?: boolean;
  onChange: (str: string) => void;
}

const SearchField: FC<
  SearchFieldProps & Omit<TextFieldProps, 'onChange' | 'value'>
> = ({ onChange, value, debounce = 0, autoFocus = false, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, debounce);

  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onChange]);

  const focusInput = useCallback(() => inputRef.current?.focus(), []);

  const handleClear = useCallback(() => {
    setSearchTerm('');
    focusInput();
  }, [setSearchTerm, focusInput]);

  const handleOnChange = useCallback(
    ({
      target: { value },
    }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchTerm(value);
    },
    [setSearchTerm],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.target.select();
      props.onFocus?.(e);
    },
    [props],
  );

  const handleKeyDown = useKeyboardEventCallback<KeyboardEvent>(
    (e) => {
      if (e.key === 'Escape') {
        handleClear();
      }
    },
    { stopPropagation: !!searchTerm },
  );

  const slotProps = useMemo<TextFieldProps['slotProps']>(() => {
    return {
      input: {
        endAdornment: (
          <IconButton
            size="small"
            sx={{ visibility: searchTerm ? 'visible' : 'hidden' }}
            onClick={handleClear}
          >
            <Clear sx={{ fontSize: '12px' }} />
          </IconButton>
        ),
        startAdornment: (
          <Box pr={1} display="flex">
            <IconButton disableRipple size="small" onClick={focusInput}>
              <Search />
            </IconButton>
          </Box>
        ),
        ...props.slotProps?.input,
      },
      htmlInput: {
        ref: inputRef,
        ...props.slotProps?.htmlInput,
      },
    };
  }, [
    searchTerm,
    handleClear,
    focusInput,
    props.slotProps?.input,
    props.slotProps?.htmlInput,
  ]);

  return (
    <TextField
      {...props}
      autoFocus={autoFocus}
      value={searchTerm}
      slotProps={slotProps}
      onFocus={handleFocus}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchField;
