import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpressionValue } from '../../../shared/models/project.interface';
import { QuantityUnit } from '../../../shared/models/unit.interface';
import { formatThousands } from '../../../shared/helpers/math_helpers';

interface IExpressionTypographyInput {
  value: ExpressionValue | number | undefined;
  unit?: QuantityUnit;
  disabled?: boolean;
  isFallback?: boolean;
}

const ExpressionTypography: React.FC<IExpressionTypographyInput> = ({
  value,
  unit,
  disabled,
  isFallback,
}) => {
  const { classes, cx } = useStyles();
  const resolved = typeof value === 'number' ? value : (value?.resolved ?? 0);
  const formatted =
    typeof value === 'number'
      ? formatThousands(value)
      : (value?.formatted ?? '-');

  const [localUnit, setLocalUnit] = useState<QuantityUnit>();

  useEffect(() => {
    setLocalUnit(unit);
  }, [unit]);

  // Multiply value with 100 if percent unit else use formatted
  const percentFormattedValue =
    localUnit === '%' && value ? formatThousands(resolved * 100) : formatted;

  // Show "-" when fallback is 0 (empty)
  const formattedValue =
    isFallback && percentFormattedValue === '0' ? '-' : percentFormattedValue;

  return (
    <Typography
      variant="body2"
      pr={2}
      className={cx(
        isFallback && classes.discrete,
        disabled && classes.disabled,
      )}
    >
      {formattedValue + ' '}
      <Typography variant="caption">{unit}</Typography>
    </Typography>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  discrete: {
    color: palette.text.disabled,
  },
  disabled: {
    color: palette.text.disabled,
  },
}));

export default ExpressionTypography;
